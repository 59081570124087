import React from "react";

function Contact() {
  return (
    <div className="row">
      <div className="col">
        <h4>Contact </h4>
        <p>
          Please contact us via email at dpfa87508@gmail.com for inquiries regarding available artworks.
        </p>
      </div>
    </div>
  );
}

export default Contact;
