import React from "react";

function Home() {
  return (
    <div className="row">
      <div className="col-12">
        <img src="images/IMG_2791.JPG" alt="IMG_2791" height="auto" width="100%"/>  
      </div>
    </div>
  );
}

export default Home;

